.container-body {
  display: flex;
  flex-direction: column;

  $page-header-height: 70px;
  $copyright-container-height: 40px;
  height: calc(100% - ($page-header-height + $copyright-container-height));
  $breadcrumb-line-height: 20px;
  $breadcrumb-margin-bottom: 4px;
  $page-content-margin-top: 24px;

  .page-title-container {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
  }

  &:has(.niagara-breadcrumb) {
    .page-title-container {
      margin-top: 0px
    }
  }

  .ui.breadcrumb {
    margin-left: 0px !important;
    margin-bottom: $breadcrumb-margin-bottom !important;
    margin-top: 8px !important;
    height: $breadcrumb-line-height;

    a.section {
      white-space: nowrap;
    }
  }

  $page-title-line-height: 32px;
  $page-title-margin-bottom: 32px;

  .page-title {
    font-weight: 800;
    font-size: 24px;
    line-height: $page-title-line-height;
  }

  .page-title-container+.page-content {
    margin-top: $page-content-margin-top;
  }

  .niagara-breadcrumb+.page-title-container+.page-content {
    height: calc(100% - ($page-title-line-height + $page-title-margin-bottom + $breadcrumb-line-height + $breadcrumb-margin-bottom));
  }

  .page-title-container+.page-content {
    height: calc(100% - ($page-title-line-height + $page-title-margin-bottom + $page-content-margin-top));
  }

  .page-content>div {
    height: 100%;
  }
}