.Toastify__toast-container {
  width: 400px;
  padding: 0;
  @media only screen and (max-width: 480px) {
    &.Toastify__toast-container--top-right {
      width: 280px;
      left: auto;
      right: 0px;
    }
  }
  .Toastify__toast {
    font-family: 'Honeywell Sans Web', sans-serif;
    padding: 24px;
    margin-bottom: 0;
    @media (max-width: 1199px) {
      padding: 16px;
    }

    .Toastify__toast-body {
      padding: 0;
      overflow: hidden;
      .app-toast {
        .toast-header {
          padding-top: 8px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #303030;
          white-space: nowrap;
          text-overflow: clip;
          text-wrap: wrap;
          @media (max-width: 1199px) {
            font-size: 12px;
            padding-top: 0;
          }
        }

        .toast-sub-header{
          padding-top: 8px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #606060;
          white-space: nowrap;
          text-overflow: clip;
        }
      
        .toast-description {
          padding-top: 12px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #606060;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .toast-actions {
          margin-top: 12px;
          
          .toast-action-button {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            padding: 4px 16px;
            letter-spacing: 0.5px;
            display: inline-block;
          }

          .secondary-button {
            color: #303030;
          }

          .primary-button {
            color: #005EAC;
          }

          .toast-action-button.right {
            float: right;
          }
          
          .toast-action-button.left {
            float: left;
          }

        }

        .toast-retry {
          margin-top: 12px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          float: right;
          padding: 4px 16px;
          color: #005EAC;
        }
        .toast-retry-api {
          margin-top: 12px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          float: right;
          padding: 4px 16px;
          color: #005eac;
          border: none;
          background: transparent;
        }
      }
      @media (max-width: 1199px) {
        .toast-content {
          display: flex;
          gap: 16px;
          width: calc(100% - 32px);
          .app-toast {
            width: calc(100% - 32px);
            .toast-description {
              padding-top: 0px;
              .toast-retry {
                padding-right: 0px;
              }
            }
          }
          .niagara-badge.success, .niagara-badge.error {
            padding: 3px;
            .badge-label {
              display: none;
            }
          }
        }
      }
    }

    .Toastify__close-button {
      position: absolute;
      right: 24px;
    }

    .niagara-badge.error {
      svg>path {
        stroke: #FFFFFF;
      }

      background-color:#D60B13;

      .badge-label {
        color: #FFFFFF;
      }
    }
  }
}