.configuration-notifications-modal {
  .modal-heading {
    font-weight: 900 !important;
  }
  .notification-type-wrapper {
    height: 555px;
    .notification-type {
      border: 1px solid #e0e0e0;
      border-radius: 12px;
      height: 220px;

      .notification-type-header {
        font-weight: 700;
        letter-spacing: 0.2px;
        padding: 14px 16px;
      }

      .ui.divider {
        border: 1px solid #e0e0e0 !important;
      }

      .notification-item {
        display: flex;
        justify-content: space-between;
        padding: 14px 16px;
        border-bottom: 1px solid #f7f7f7;

        .notification-item-name {
          font-size: 14px;
          font-weight: 500;
          color: #606060;
        }
      }
    }
  }
  .button-container {
    padding-right: 14px;
    gap: 13px;
  }
}
