.retrieval-usage {
  flex-direction: column;
  height: 6rem;

  .progress-container {
    position: relative;

    .baseline-line {
      position: absolute;
      height: 2rem;
      width: 0.06rem;
      background-color: #606060;
      top: -8.0px;
    }

    .baseline-text {
      display: flex;
      position: absolute;
      top: 20px;

      .baseline-value {
        @extend .text-style;
        color: #303030;
        margin-right: 0.2rem;
      }

      .baseline-info {
        @extend .text-style;
        color: #707070;
      }
    }

    .usage-line {
      align-items: center;
      display: flex;
      flex-direction: column;
      position: absolute;
      height: 2rem;
      width: 2rem;
      top: 10px;
      @media (max-width:767px) {
        right: -16px;
        left: auto !important;
      }

      .info-text {
        font-family: 'Honeywell Sans Web', sans-serif;
        width: 3.375rem;
        height: 2rem;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: #606060;
      }
    }

    .text-style {
      font-family: 'Honeywell Sans Web', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .scuf-progress-wrapper .progress-text {
      visibility: hidden;
    }

    .scuf-progress-wrapper.xlarge .bar {
      height: 16px !important;
    }

  }
}

[data-theme='dark'] {
  @import '../../DarkModeColor';
  
  .retrieval-usage {
    .scuf-progress-wrapper.stacked .bars-wrapper {
      background-color: $background-color;
      height: 100%;
    }

    .progress-container {
      .baseline-text .baseline-value {
        color: $font-color;
      }
      .usage-line .indicator{
        svg {
          > path {
            fill: #FF644C;
          }
        }
      }
      .usage-line .info-text {
        color: $input-text-color;
      }

      .baseline-text .baseline-info {
        color: $stroke-color;
      }
    }
  }

}