#notification-popup {
  border-radius: 8px !important;
}
.notification-model-container {
  width: 433px;
  height: calc(100vh - 130px);
  border-radius: 8px;
  border: 1px solid #d0d0d0;
  padding-bottom: 8px;
  background-color: #f7f7f7;
  .notification-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 800;
      letter-spacing: 2px;
    }
    .notification-read {
      border: 1px solid #adaaaa;
      padding: 2px;
      border-radius: 4px;
      background-color: rgba(240, 240, 240, 1);
      height: 32px;
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15) inset;
      display: inline-flex;
      gap: 16px;
      .all-btn {
        border-radius: 3px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(224, 224, 224, 1);
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
      }
      .unread-btn {
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        margin-left: auto;
      }
    }
  }
  .ui.divider {
    border: 1px solid #d0d0d0 !important;
  }

  .error-body {
    width: 90% !important;
    position: absolute;
    top: 50%;
    left: 38%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .niagara-loader {
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .card-content {
    max-height: calc(100vh - 260px);
    overflow: auto;
    background-color: #f0f0f0 !important;
    height: calc(100vh - 272px);
    border-bottom: 1px solid #d0d0d0 !important;
    .no-notification {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .notifications-item {
      background: #fff;
      padding: 12px 16px 13px 14px;
      display: flex;
      &.read {
        padding-left: 22px;
      }
      .unread-icon {
        line-height: 16px;
      }
      .notification-content {
        padding-left: 8px;
        width: 100%;
        .notification-body {
          display: flex;
          margin: auto;
          gap: 24px;
          .title-content {
            .subject {
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              margin: auto;
            }
            font-size: 12px;
            line-height: 16px;

            .description {
              color: #606060;
              line-height: 24px;
              span {
                font-weight: 700;
                color: #303030;
              }
            }
          }
          .expiray-badge {
            text-align: right;
            margin-left: auto;
            .notification-badge {
              .badge-label {
                color: #ffffff;
              }
              svg > path {
                stroke: #ffffff !important;
              }
              .orange {
                background-color: #ba5c0a;
                font-weight: 700;
              }
              .red,
              .error {
                background-color: #d60b13;
                font-weight: 700;
              }
              .success {
                background-color: #007000;
                font-weight: 700;
              }
            }
          }
        }
        .notification-footer {
          height: 32px;
          display: flex;
          font-size: 12px;
          line-height: 16px;
          color: #606060;
          .expiry-date {
            margin: auto auto auto 0;
          }
          .navigation-link {
            margin-left: auto;
            line-height: 20px;
            button {
              padding-right: 4px !important;
            }
          }
        }
      }
      &.read > .notification-content .notification-body .title-content {
        .subject,
        .description > span {
          font-weight: 500;
        }
      }
    }
  }
  .MarkRead {
    padding: 4px 0px 0px 0px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
  }
}
